<template>
  <span id="rule">.......................................................................................................................</span>
</template>

<script>
</script>

<style>
    #rule {
        width: 75%;
        overflow: hidden;
    }
</style>