<template>
    <div id="whole-thing">
        <div class="box main">
            <w3m-button></w3m-button>
            <h1>PacMiner <img src="../assets/icon.png" alt=""></h1>
            <p>The algorithmic miner on Base, with built in sustainability and self reliant mechanics.</p>
            <a @click="addToMetamask">Add to metamask</a>
            <a href="https://basescan.org/token/0xe2c607344C8b3f8c92ebB09DFCF9dd2eAE86A1E5" target="_blank">Explorer link</a>
            <Rule/>
            <h3>Steps</h3>
            <ol>
                <li>Buy GHOST with ETH - from PacMiner or BaseSwap</li>
                <li>Hold GHOST to earn ETH</li>
                <li>Compound ETH to GHOST (avoids deposit fee)</li>
                <li>Sell GHOST</li>
            </ol>
            <p>The price of GHOST is determined by how many GHOST are in circulation. The more GHOST you hold,
                the higher the share of the reward pool you will earn. 
            </p>
        
        
        </div>
        <div class="box main">
            <div class="box info" >
                <div class="stat">
                    <p>TVL</p>
                    <p>${{ represent(info.circulatingMarketCapUSD, 2, 6) }}</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>APY</p>
                    <p>{{info.apy}}%</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>Dev Fee</p>
                    <p>{{info.devPercentage}}%</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>Deposit Fee</p>
                    <p>{{info.instantSplitPercentage + info.depositFee + info.liquidityPercentage}}%</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>Referrals</p>
                    <p>{{info.referralPercentage}}%</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>Supply</p>
                    <p>{{info.circulatingSupply}} GHOST</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>Balance</p>
                    <p>{{userInfo.balance}} GHOST</p>
                </div>
            </div>
            <div class="stat">
                <p>Ref</p>
                <input type="text" v-model="referralInput">
            </div>
            <hr>
            <button @click="getRef">COPY REF</button><hr><hr>
        </div>
        <div class="box main">
            <hr>
            <div>
                <input type="text" v-model="mintInput">
                <input type="text" class="inputInfo" value="ETH" disabled>
            </div>
            <p>{{ toReceiveGHOST }} GHOST</p>
            <button @click="mint">MINT</button><hr>
            <Rule /><hr>
            <div>
                <input type="text" v-model="sellInput">
                <input type="text" class="inputInfo" value="GHOST" disabled>
            </div>
            <p>{{ represent(toReceiveETH, 6) }} ETH</p>
            <button @click="sell">SELL</button><hr>
            <Rule /><hr>
            <div class="box info">
                <div class="stat">
                    <p>Earning</p>
                    <p>{{represent(userInfo.earningsPerDay, 6)}} ETH</p>
                    <p>per day</p>
                </div>
                <Rule />
                <div class="stat">
                    <p>Claimable</p>
                    <p>{{represent(userInfo.claimable, 6)}} ETH</p>
                </div>
            </div><hr>
            <button @click="compound">COMPOUND</button><hr>
            <button @click="claim">CLAIM</button><hr>
        </div>
    </div>
</template>

<script setup>
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/vue";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import {address, abi} from '../contract.js'
import Rule from './Rule.vue'
import { ethers } from "ethers";
const projectId = '859ae4f8954aa5a0c905dfdc08835318'
const represent = (value, decimals, d) => Number(ethers.formatUnits(value, d || 18)).toFixed(decimals)
// const explorerLink = ref(`https://basescan.org/token/0x6aE0DE4f84c1cddEb08868aC2e7e07681A728522`)
// const base = {
//   chainId: 168587773,
//   name: 'Blast Sepolia',
//   currency: 'ETH',
//   explorerUrl: 'https://testnet.blastscan.io/',
//   rpcUrl: 'https://sepolia.blast.io'
// }

const base = {
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: "https://basescan.org/",
    rpcUrl: "https://base-pokt.nodies.app"
}

const metadata = {
  name: '',
  description: '',
  url: '', // url must match your domain & subdomain
  icons: []
}

const ethersConfig = defaultConfig({
  metadata,
})

// 5. Create a Web3Modal instance
const modal = createWeb3Modal({
  ethersConfig,
  chains: [base],
  projectId
})

const { address: userAddress, chainId } = useWeb3ModalAccount()
const { walletProvider: provider } = useWeb3ModalProvider()

const info = ref({
    circulatingMarketCapUSD: BigInt(0),
    circulatingSupply: BigInt(0),
    apy: BigInt(0),
    referralPercentage: BigInt(0),
    devPercentage: BigInt(0),
    instantSplitPercentage: BigInt(0),
    depositFee: BigInt(0),
    liquidityPercentage: BigInt(0)

})
const userInfo = ref({
    balance: BigInt(0),
    claimable: BigInt(0),
    earningsPerDay: BigInt(0)
})
const toReceiveGHOST = ref(BigInt(0))
const toReceiveETH = ref(BigInt(0))
function getRef() {
    navigator.clipboard.writeText(window.location.href + '?ref=' + userAddress.value)
}
let interval
const mintInput = ref()
const sellInput = ref()
const referralInput = ref((new URLSearchParams(window.location.search)).get('ref'))
const updater = async () => {
    try {
        const contract = await getContract()
        info.value = await contract.getInfo()
        userInfo.value = await contract.getUserInfo(userAddress.value)
    } catch(e) {
        console.error(e)
    }
}
async function addToMetamask() {
    try {
        await provider.value.request({ method: 'wallet_watchAsset', params: { type: 'ERC20', options: { address } } })
    } catch(e) {
        console.error(e)
    }
}
async function getContract() {
    if(chainId.value !== base.chainId) {
        return null
    }
    const signer = await (new ethers.BrowserProvider(provider.value)).getSigner()
    return new ethers.Contract(address, abi, signer)
}
onMounted(() => {
    setTimeout(updater, 2000)
    interval = setInterval(updater, 5000)
})
onUnmounted(() => {
    clearInterval(interval)
})

const referral = computed(() => {
    try {
        return ethers.getAddress(referralInput.value)
    } catch {
        return ethers.ZeroAddress
    }
})
const toMint = computed(() => {
    try {
        return ethers.parseEther(mintInput.value)
    } catch {
        return BigInt(0)
    }
})
const toSell = computed(() => {
    try {
        return BigInt(sellInput.value)
    } catch {
        return BigInt(0)
    }
})

watch(toMint, async (value) => {
    try {
        toReceiveGHOST.value = await (await getContract()).quoteBuy(referral.value, value)
    } catch(e) {
        console.error(e)
    }
})
watch(toSell, async (value) => {
    try {
        toReceiveETH.value = await (await getContract()).quoteSell(value)
    } catch(e) {
        console.error(e)
    }
})

const mint = async () => {
    try {
        await (await (await getContract()).purchase(referral.value, {value: toMint.value})).wait()
        updater()
    } catch(e) {
        console.error(e)
    }
}
const sell = async () => {
    try {
        await (await (await getContract()).sell(toSell.value)).wait()
        updater()
    } catch(e) {
        console.error(e)
    }
}
const compound = async () => {
    try {
        await (await (await getContract()).compound()).wait()
        updater()
    } catch(e) {
        console.error(e)
    }
}
const claim = async () => {
    try {
        await (await (await getContract()).claim()).wait()
        updater()
    } catch(e) {
        console.error(e)
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Tiny5&display=swap');
    a:hover {
        cursor: pointer;
    }
    #whole-thing {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        
    }
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-family: "Tiny5";
        border-radius: 10px;
        font-size: 1.3rem;
        margin: 20px;
    }
    .stat {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 75%;
        font-size: 1.5rem;
    }
    .main {
        border-style: solid;
        padding: 20px;
        background-color: rgba(91, 228, 254, 0.95);
        margin-block: 20vh;
        width: 450px;
        color: black;
    }
    .info {
        border-style: solid;
        border-width: 3px;
        border-color: black;
        border-radius: 10px;
        padding: 5px;
        width: 75%;
    }
    w3m-button {
        align-self: flex-end;
    }
    ul {
        list-style-type: none;
    }
    .stat input {
        width: 75%;
        font-size: 1.5rem;
    }
    input {
        border-radius: 5px;
        font-family: "Tiny5";
        font-size: 2rem;
        width: 50%;
    }
    .inputInfo {
        width: 25%;
    }
    button {
        font-family: "Tiny5";
        font-size: 2rem;
        background-color: black;
        color: white;
        border-radius: 5px;
        padding: 10px;
    }
    h1 {
        font-size: 3rem;
    }

</style>